import styled from 'styled-components'

export const CardWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid var(--brand-primary-silver-color);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  -webkit-highlight: none !important;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--brand-primary-white-color);

  .videoCont {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .autoplayVideo {
    object-fit: cover;
    max-height: 275px;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .productDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex-grow: 1;
    height: auto;
  }
  .productDetail__new_design {
    padding: 0 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 6px;
    width: 100%;

    .productName {
      text-align: left;
      margin: 10px 0;
      white-space: break-spaces;
    }
    .ratingsReview {
      justify-content: left;
    }
    .prices {
      justify-content: left;
    }

    @media (min-width: 768px) {
      padding: 10px 0 0 58px;

      .productName {
        text-align: left;
        margin: 14px 0;
        max-height: 95px;
        overflow: hidden;
      }
    }
  }

  .ratingsReview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    height: 12px;
    margin-bottom: 0;
  }

  .reviewValsOuter {
    display: flex;
    align-items: flex-end;
    height: 15px;
  }

  .reviewVals {
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-400);
    color: var(--brand-font-default-color);
    margin-left: 2px;
    font-size: 10px;
    line-height: 11px;
  }

  .verifiedimg {
    object-fit: cover;
    margin-left: 3px;
    margin-bottom: 1px;
  }

  .productName {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    font-family: var(--brand-font-family);
    font-style: normal;
    font-weight: var(--brand-font-weight-700);
    font-size: 12px;
    line-height: 12px;
    color: var(--brand-font-default-color);
    max-width: 300px;
    -webkit-line-clamp: 3;
    text-align: center;
    max-height: 95px;
    overflow: hidden;
  }

  .prices {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .discountP {
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-700);
    font-size: 18px;
    line-height: 17px;
    color: var(--brand-font-default-color);

    .rupeeSup {
      font-size: 9.5px;
      line-height: 11px;
    }
  }

  .actualP {
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-400);
    font-size: 24px;
    line-height: 24px;
    font-size: 10px;
    line-height: 11px;
    color: var(--brand-primary-grey-color);
    margin: 0 4px;
    text-decoration: line-through;
  }

  .discountText {
    width: auto;
    min-width: 46px;
    height: 14px;
    background: var(--category-primary-color);
    border-radius: 3px;
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-700);
    font-size: 10px;
    line-height: 11px;
    color: var(--brand-primary-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }

  .cartAdd {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background: var(--brand-primary-black-color);
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-700);
    font-size: 12px;
    line-height: 14px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: var(--brand-primary-white-color);
  }

  .cardAdd-label {
    cursor: pointer;
  }

  .whyThisWorks {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    line-height: 11px;
    font-weight: var(--brand-font-weight-normal);
    padding-top: 10px;
    min-height: 22px;
    gap: var(--dls-size-8);

    .why-this-work-item {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }
    .key {
      display: inline-block;
      margin-right: 2px;
      color: var(--category-primary-color);
      font-weight: var(--brand-font-weight-bold);
    }
  }

  @media (min-width: 768px) {
    width: 90%;

    .autoplayVideo {
      max-height: 508px;
    }
    .reviewVals {
      margin-left: 3px;
      font-size: 16px;
      line-height: 18px;
    }

    .reviewValsOuter {
      align-items: center;
    }

    .productName {
      font-size: 24px;
      line-height: 24px;
    }

    .discountP {
      font-size: 36px;
      line-height: 36px;

      .rupeeSup {
        font-size: 19px;
      }
    }

    .actualP {
      font-size: 18px;
      line-height: 21px;
    }

    .discountText {
      min-width: 75px;
      height: 22px;
      border-radius: 5px;
      line-height: 18px;
      font-size: 16px;
      line-height: 18px;
      padding: 0 4px;
    }

    .cartAdd {
      height: 60px;
      font-size: 20px;
      line-height: 23px;
      margin-top: 30px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .whyThisWorks {
      font-size: 16px;
      line-height: 22px;
      min-height: 44px;

      .key {
        margin-right: 8px;
      }
    }
  }
`
